@if (showBanner) {
  <div class="banner">
    <button (click)="hideBannerAction()" mat-button class="banner__close-button">
      <ion-icon name="close-outline"></ion-icon>
    </button>

    <h3 class="banner__title">{{ 'CookieBanner.Title' | translate }}</h3>
    <p [innerHTML]="'CookieBanner.Message' | translate" class="banner__text"></p>

    <div class="banner__buttons">
      <button mat-button (click)="acceptConsent()" class="btn btn--primary fill-sm">
        {{ 'CookieBanner.Accept' | translate }}
      </button>
      <button mat-button (click)="denyConsent()" class="btn btn--secondary fill-sm">
        {{ 'CookieBanner.Deny' | translate }}
      </button>
    </div>
  </div>
}
