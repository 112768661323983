import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Injector, OnInit, PLATFORM_ID } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { LanguageService } from 'src/services/shared/language.service';
import { TrackingService } from 'src/services/tracking.service';
import { SessionManagerService } from 'src/utils/session-manager';
import { getStorageObject } from 'src/utils/storage-manager';

import { CookieBannerComponent } from './cookie-banner/cookie-banner.component';
import { FooterComponent } from './shared-components/footer/footer.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, FooterComponent, CookieBannerComponent, TranslateModule]
})
export class AppComponent implements OnInit {
  title = 'rent-a-bus-front';

  /**
   * AppComponent constructor
   * @param platformId
   * @param sessionManager Used to manage different user sessions
   * @param trackingService Used to track user interactions
   * at the same time. It is not necessary to use it, just
   * declare it in the constructor.
   * @param injector Used to inject the language service only in
   * the context of the user browser.
   */
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private sessionManager: SessionManagerService,
    private trackingService: TrackingService,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.sessionManager.listenToStorageChanges();
      const languageService = this.injector.get(LanguageService);
      languageService.listenToLanguageChanges();
      this.loadExternalStyle(
        'https://customwp.movenbus.com/wp-content/plugins/easy-accordion-free/public/assets/css/ea-style.css?ver=2.3.10'
      );
      this.loadExternalStyle('https://customwp.movenbus.com/wp-content/themes/movenbus/custom.css');
    }

    if (isPlatformBrowser(this.platformId)) {
      const consent = getStorageObject('consentMode') === 'granted';
      if (consent) {
        this.trackingService.start();
      }
    }
  }

  /**
   * Load the css required by WordPress.
   * This way we avoid breaking the entire application if the
   * WordPress server is down.
   * @param url URL of the external style to load
   */
  loadExternalStyle(url: string): Promise<void> {
    return new Promise((resolve) => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = url;
      link.onload = () => resolve();
      link.onerror = () => {
        resolve();
      };
      document.head.appendChild(link);
    });
  }
}
