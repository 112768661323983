import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionManagerService {
  constructor() {}

  /**
   * Listens to user login events, if there is any, reloads other tabs
   * to prevent having sessions with different user data.
   */
  listenToStorageChanges(): void {
    window.addEventListener('storage', (event) => {
      if (event.key === 'userData') {
        window.location.reload();
      }
    });
  }
}
