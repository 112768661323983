import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Params, Router, RouterLink } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UserRole } from 'src/definitions/user-role.enum';
import { AuthService } from 'src/shared-components/ng-login/auth/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [RouterLink, TranslateModule]
})
export class FooterComponent implements OnInit, OnDestroy {
  currentYear = new Date().getFullYear();
  isAdminRoute? = false;
  routerSubscription?: Subscription;
  isLogged: boolean = false;
  logoUrl = '/home';

  constructor(
    private router: Router,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe((event) => {
      this.isLogged = this.auth.loggedIn();
      if (event instanceof NavigationEnd) {
        event.url.includes('admin') ? (this.isAdminRoute = true) : (this.isAdminRoute = false);
        if (event.url.includes('login') || event.url.includes('signup') || event.url.includes('forgot-password')) {
          this.logoUrl = '/home';
        } else {
          this.logoUrl = this.logoUrlRedirect();
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  redirectToContactParams(): Params | undefined {
    if (this.auth.hasSameRole(UserRole.OPERATOR)) {
      return { role: UserRole.OPERATOR };
    } else if (this.auth.hasSameRole(UserRole.CLIENT)) {
      return { role: UserRole.CLIENT };
    } else {
      return undefined;
    }
  }

  logoUrlRedirect(): string {
    if (this.auth.hasSameRole(UserRole.OPERATOR)) {
      return '/operator';
    } else if (this.auth.hasSameRole(UserRole.ADMIN)) {
      return '/admin';
    } else {
      return '/home';
    }
  }
}
