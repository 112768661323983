import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { getStorageObject, setStorageObject } from 'src/utils/storage-manager';

@Component({
  selector: 'app-cookie-banner',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [TranslateModule, MatButton],
  templateUrl: './cookie-banner.component.html',
  styleUrl: './cookie-banner.component.scss'
})
export class CookieBannerComponent implements OnInit {
  /**
   * Display o not the banner
   */
  showBanner = false;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.showBanner = !getStorageObject('consentMode');
  }

  acceptConsent(): void {
    this.hideBannerAction();

    setStorageObject('consentMode', 'granted');
  }
  hideBannerAction(): void {
    this.showBanner = false;
  }

  denyConsent(): void {
    this.hideBannerAction();

    setStorageObject('consentMode', 'denied');
  }
}
